import React from "react";

import { Avatar, Card, CardHeader, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    cardContainer: {
        minHeight: 128,
        display: 'flex',
        justifyContent: 'center',
        gap: 16,
    },
    cardHeaderContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
	cardAvatar: {
		fontSize: '64px',
		color: grey[500],
		backgroundColor: 'transparent',
		width: theme.spacing(9),
		height: theme.spacing(9)
	},
	cardTitle: {
		fontSize: '20px',
        fontWeight: 'bold',
		color: theme.palette.text.primary
	},
	cardSubtitle: {
		color: grey[600],
        fontWeight: 'bold',
		fontSize: '18px'
	}
}));

export default function CardCounter(props) {
    const { icon, title, value, loading, backgroundColor, textColor } = props
	const classes = useStyles();
    return ( !loading ? 
        <Card className={classes.cardContainer} style={{ background: backgroundColor }}>
            <CardHeader
                classes={{ content: classes.cardHeaderContent }}
                avatar={
                    <Avatar className={classes.cardAvatar} style={{color: textColor}}>
                        {icon}
                    </Avatar>
                }
                title={
                    <Typography variant="h6" component="h2" className={classes.cardTitle} style={{color: textColor}}>
                        { title }
                    </Typography>
                }
                subheader={
                    <Typography variant="subtitle1" component="p" className={classes.cardSubtitle} style={{color: textColor}}>
                        { value }
                    </Typography>
                }
            />
        </Card>
        : <Skeleton variant="rect" height={80} />
    )
    
}