import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey, red, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Chip, IconButton, Menu, MenuItem, Popover, Popper, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import RoomIcon from '@material-ui/icons/Room';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import DoneIcon from '@material-ui/icons/Done';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    height: 98,
    paddingHorizontal: 10,
    paddingVertical: 0
  },
  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -23,
    fontSize: 12
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "50%",
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 0,
    marginLeft: "auto",
    top: -10,
    right: 10
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    right: 0,
    top: 10
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: 0
  },

  ticketInfo1: {
    position: "relative",
    top: 40,
    right: 0
  },
  Radiusdot: {

    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
      fontSize: 10,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
  badgeDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

const TicketListItemCustom = ({ ticket, setUpdate }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [whatsAppName, setWhatsAppName] = useState(null);

  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [badgePopoverOpen, setBadgePopoverOpen] = useState(false);

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name);
    }

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name);
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickExpandOptions = (event) => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setOptionsMenuOpen(true);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
    setOptionsMenuOpen(false);
  };

  const handleOpenBadgePopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setBadgePopoverOpen(true);
  };
  const handleCloseBadgePopover = (event) => {
    event.stopPropagation();
    setBadgePopoverOpen(false);
  };

  const handleCloseTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        justClose: true,
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/`);
    setUpdate(Math.random())

    setLoading(false);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${ticket.uuid}`);
    setUpdate(Math.random())
    setLoading(false);
  };

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <>
          <div
            style={{ display: 'inline-block' }}
            onMouseEnter={handleOpenBadgePopover}
          >
            <div
              className={clsx(classes.badgeDot)}
              style={{
                backgroundColor: "#008080",
                color: "white",
                marginRight: 3
              }}
            ></div>
            {ticket.whatsappId && (
              <div
                className={clsx(classes.badgeDot)}
                style={{
                  backgroundColor: red[400],
                  color: "white",
                  marginRight: 3
                }}
              ></div>
            )}
            {ticket.queue?.name !== null && (
              <div
                className={clsx(classes.badgeDot)}
                style={{
                  backgroundColor: ticket.queue?.color || "#7C7C7C",
                  color: "white",
                  marginRight: 3
                }}

              ></div>
            )}
          </div>
          <Popover
            open={badgePopoverOpen}
            anchorEl={anchorEl}
            onClose={handleCloseBadgePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box style={{ padding: 8, display: 'flex', gap: 4 }}>
              <Chip
                size="small"
                label={ticketUser}
                style={{ backgroundColor: '#008080', color: 'white' }}
              />
              {ticket.whatsappId && (
                <Chip
                  size="small"
                  label={whatsAppName}
                  style={{ backgroundColor: red[400], color: 'white' }}
                />
              )}
              {ticket.queue?.name !== null && (
                <Chip
                  size="small"
                  label={ticket.queue?.name || "Sem fila"}
                  style={{ backgroundColor: ticket.queue?.color || "#7C7C7C", color: 'white' }}
                />
              )}
            </Box>
          </Popover>

          <IconButton
            size="small"
            style={{
              position: 'absolute',
              right: 0,
              top: -12,
            }}
            onClick={handleClickExpandOptions}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Menu
            id="ticket-list-options"
            anchorEl={anchorEl}
            open={optionsMenuOpen}
            onClose={handleCloseOptions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

          >
            {ticket.status === "open" && (
              <MenuItem onClick={(e) => { handleCloseOptions(); handleCloseTicket(ticket.id) }}>Fechar Conversa</MenuItem>
            )}
            {profile === "admin" && (
              <MenuItem onClick={(e) => { handleCloseOptions(); setOpenTicketMessageDialog(true) }}>Espiar Conversa</MenuItem>
            )}
          </Menu>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}

        </>
      );
    } else {
      return (
        <>

          <div
            style={{ display: 'inline-block' }}
            onMouseEnter={handleOpenBadgePopover}
          >
            {ticket.whatsappId && (
              <div
                className={clsx(classes.badgeDot)}
                style={{
                  backgroundColor: red[400],
                  color: "white",
                  marginRight: 3
                }}
              ></div>
            )}
            {ticket.queue?.name !== null && (
              <div
                className={clsx(classes.badgeDot)}
                style={{
                  backgroundColor: ticket.queue?.color || "#7C7C7C",
                  color: "white",
                  marginRight: 3
                }}

              ></div>
            )}
          </div>
          <Popover
            open={badgePopoverOpen}
            anchorEl={anchorEl}
            onClose={handleCloseBadgePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box style={{ padding: 8, display: 'flex', gap: 4 }}>
              {ticket.whatsappId && (
                <Chip
                  size="small"
                  label={whatsAppName}
                  style={{ backgroundColor: red[400], color: 'white' }}
                />
              )}
              {ticket.queue?.name !== null && (
                <Chip
                  size="small"
                  label={ticket.queue?.name || "Sem fila"}
                  style={{ backgroundColor: ticket.queue?.color || "#7C7C7C", color: 'white' }}
                />
              )}
            </Box>
          </Popover>

          <IconButton
            size="small"
            style={{
              position: 'absolute',
              right: 0,
              top: -12,
            }}
            onClick={handleClickExpandOptions}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Menu
            id="ticket-list-options"
            anchorEl={anchorEl}
            open={optionsMenuOpen}
            onClose={handleCloseOptions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

          >
            {(ticket.status === "pending" || ticket.status === "open") && (
              <MenuItem onClick={(e) => { handleCloseOptions(); handleCloseTicket(ticket.id) }}>Fechar Conversa</MenuItem>
            )}
            {ticket.status === "pending" && (
              <MenuItem onClick={(e) => { handleCloseOptions(); handleAcepptTicket(ticket.id) }}>Aceitar Conversa</MenuItem>
            )}
            {profile === "admin" && (
              <MenuItem onClick={(e) => { handleCloseOptions(); setOpenTicketMessageDialog(true) }}>Espiar Conversa</MenuItem>
            )}
          </Menu>

          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };


  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>
      <ListItem
        dense
        button
        disableRipple
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>

              <Typography
                noWrap
                component="span"
                variant="body2"
                style={{ fontSize: 16, display: 'flex', alignItems: 'start', gap: 4 }}
              >
                {ticket.channel === "whatsapp" && (
                  <Tooltip title={`Atribuido à ${ticketUser}`}>
                    <WhatsAppIcon fontSize="inherit" />
                  </Tooltip>
                )}{' '}
                {ticket.contact.name}
              </Typography>

            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              > {ticket.lastMessage.includes('data:image/png;base64') ? <MarkdownWrapper> Localização</MarkdownWrapper> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>}
                {/* {ticket.lastMessage === "" ? <br /> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>} */}
              </Typography>
              <ListItemSecondaryAction style={{ left: 73 }}>
                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
              </ListItemSecondaryAction>
            </span>

          }
        />
        <ListItemSecondaryAction style={{}}>
          {ticket.status === "closed" && (
            <Badge
              className={classes.Radiusdot}
              badgeContent={"FECHADO"}
              //color="primary"
              style={{
                backgroundColor: ticket.queue?.color || "#ff0000",
                height: 18,
                padding: 5,
                paddingHorizontal: 12,
                borderRadius: 7,
                color: "white",
                top: -28,
                marginRight: 5

              }}
            />
          )}

          {ticket.lastMessage && (
            <>

              <Typography
                className={classes.lastMessageTime}
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                )}
              </Typography>

              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages ? ticket.unreadMessages : null}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
              <br />

            </>
          )}

        </ListItemSecondaryAction>

      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;