import React from 'react';
import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    activeButton: {
        backgroundColor: 'rgba(234, 234, 234, 0.3)',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.3)',
        },
        '& *': {
            color: theme.palette.primary.main,
        }
    },
}));

export const ToggleIconButton = ({
    children,
    title,
    onChange,
    selected,
}) => {
    const classes = useStyles();

    const handleClick = () => {
        onChange();
    };

    return (
      <IconButton
        onClick={handleClick}
        title={title}
        className={selected ? classes.activeButton : ""}
      >
        {children}
      </IconButton>
    );
}