import React, { useState, useContext } from "react";

import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Grid
} from '@material-ui/core';

import api from "../../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

const SupportNew = () => {
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [subject, setSubject] = useState('');
    const [priority, setPriority] = useState('');
    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState(null);

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('subject', subject);
            formData.append('priority', priority);
            formData.append('message', message);

            if (attachments) {
                formData.append('file', attachments);
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            await api.post("/support/new", formData, config);
            toast.success(i18n.t("support.toasts.success"));
            history.push("/supports");
        } catch (err) {
            console.log(err);
            toast.error(i18n.t("support.toasts.error"));
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1>Abrir chamado</h1>
            <Paper style={{ padding: '20px' }}>
                <Grid container spacing={3}>
                    {/* Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Nome"
                            value={name}
                            disabled
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Ex: João da Silva"
                            required
                        />
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="E-mail"
                            value={email}
                            disabled
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ex: joaodasilva@hotmail.com"
                            required
                        />
                    </Grid>
                    {/* Subject */}
                    <Grid item xs={8} md={10}>
                        <TextField
                            fullWidth
                            label="Assunto"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Ex: Problema com o pagamento"
                            required
                        />
                    </Grid>
                    {/* Priority */}
                    <Grid item xs={4} md={2}>
                        <FormControl fullWidth>
                            <InputLabel>Prioridade</InputLabel>
                            <Select
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                <MenuItem value={"low"}>Baixa</MenuItem>
                                <MenuItem value={"medium"}>Média</MenuItem>
                                <MenuItem value={"high"}>Alta</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Message */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Messagem"
                            multiline
                            rows={7}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Ex: Olá, estou com problemas para realizar o pagamento."
                            required
                        />
                    </Grid>
                    {/* Attachments */}
                    <Grid item xs={12}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                const allowedSize = 25 * 1024 * 1024; // 25MB

                                if (file && file.size <= allowedSize) {
                                    setAttachments(file);
                                } else {
                                    toast.error(
                                        i18n.t("support.toasts.attachment_error")
                                    );
                                }
                            }}
                        />
                        {attachments && (
                            <div>
                                <p>Arquivo anexado: {attachments.name}</p>
                            </div>
                        )}
                    </Grid>
                    {/* Submit button */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Enviar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default SupportNew;