import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { Container, Typography, Tooltip, Paper, Box, Button, Card, CardContent, Dialog, TextField, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import { format } from 'date-fns';
import { toast } from "react-toastify";

const SupportDetail = ({ match }) => {
    const [ticket, setTicket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [attachments, setAttachments] = useState([]);

    const ticketId = match.params.id;
    const isClosed = ticket && ticket.isClosed === true;

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClosedTicket = async () => {
        try {
            await api.put(`/support/${ticketId}/close`);

            toast.success("Pronto! O chamado foi fechado com sucesso.");

            setTimeout(() => {
                window.location.href = '/supports';
            }, 2000);
        } catch (err) {
            console.error("Erro ao fechar o chamado", err);
        }
    };

    const [userMessage, setUserMessage] = useState('');
    const [userAttachments, setUserAttachments] = useState(null);

    const isImage = (file) => {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        return file && acceptedImageTypes.includes(file['type']);
    };

    const submitMessage = async () => {
        try {
            const formData = new FormData();
            formData.append('message', userMessage);

            if (userAttachments && isImage(userAttachments)) {
                formData.append('file', userAttachments);
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await api.put(`/support/${ticketId}`, formData, config);

            const updatedMessages = [...messages, response.data.messages.pop()];
            setMessages(updatedMessages);

            const updatedAttachments = [...attachments, response.data.attachments.pop()];
            setAttachments(updatedAttachments);

            setUserMessage('');
            setUserAttachments(null);

            handleCloseModal();

            toast.success("Pronto! Sua mensagem foi enviada com sucesso.");
        } catch (err) {
            console.error("Erro ao enviar a mensagem", err);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // * Carrega os detalhes do ticket
                const ticketResponse = await api.get(`/support/${ticketId}`);
                setTicket(ticketResponse.data);

                // * Carrega as mensagens associadas ao ticket
                const messagesResponse = await api.get(`/support/${ticketId}/messages`);
                setMessages(messagesResponse.data);

                // * Carrega os anexos associados ao ticket
                const attachmentsResponse = await api.get(`/support/${ticketId}/attachaments`);
                setAttachments(attachmentsResponse.data);
            } catch (err) {
                console.error("Erro ao buscar os dados", err);
            }
        };

        fetchData();
    }, [ticketId]);

    return (
        <Container color="primary" style={{ marginTop: '2.5rem' }}>
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h4">Detalhes do Chamado</Typography>
            </Box>

            {ticket && (
                <Paper elevation={3} style={{ padding: '.8rem', marginBottom: '1rem' }}>
                    <Typography variant="h5" style={{ marginBottom: '1rem' }}>{ticket.subject}</Typography>
                    <Typography><strong>Ticket:</strong> #{ticket.id}</Typography>
                    <Typography><strong>Situação:</strong> {ticket.isClosed === false ? 'Aberto' : 'Fechado'}</Typography>
                    <Typography><strong>Última Atualização:</strong> {format(new Date(ticket.updatedAt), 'dd/MM/yyyy HH:mm:ss')}</Typography>
                    <Typography>
                        <strong>Prioridade:</strong>
                        <span style={{ color: ticket.priority === 'low' ? 'green' : ticket.priority === 'medium' ? 'orange' : 'red' }}>
                            {ticket.priority === 'low' ? ' Baixa' : ticket.priority === 'medium' ? ' Média' : ' Alta'}
                        </span>
                    </Typography>
                </Paper>
            )}

            {messages && messages.length > 0 && (
                <div>
                    <Typography variant="h6" style={{ marginBottom: '.8rem' }}>Mensagens</Typography>
                    {messages.map((message, index) => (
                        <Card key={index} style={{ marginBottom: '1rem' }}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Box>
                                        <Typography color="textPrimary" weight="bold">
                                            {message.user.super === true ? 'Equipe Técnica' : message.user.name}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {message.user.super === true ? 'Atendente' : 'Cliente'}
                                        </Typography>
                                    </Box>
                                    <Typography color="textSecondary" gutterBottom>
                                        {format(new Date(message.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                                    </Typography>
                                </Box>
                                <Typography variant="body2">
                                    {message.message}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )
            }

            {attachments && attachments.length > 0 && (
                <div>
                    <Typography variant="h6" style={{ marginBottom: '.8rem' }}>Imagens Anexadas</Typography>
                    <Card style={{ marginBottom: '1rem' }}>
                        <CardContent>
                            <Box display="flex" flexWrap="wrap" justifyContent="start">
                                {attachments.map((attachment, index) => (
                                    attachment.attachment && (
                                        <Tooltip key={index} title="Clique para abrir a imagem" placement="top">
                                            <Box p={1}>
                                                <img
                                                    src={attachment.attachment}
                                                    alt={`Anexo ${index + 1}`}
                                                    style={{ maxWidth: '120px', cursor: 'pointer' }}
                                                    onClick={() => window.open(attachment.attachment, '_blank')}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            )}

            {isClosed === false ? (
                <Box my={4}>
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>Responder</Button>
                    <Button variant="outlined" color="secondary" onClick={handleClosedTicket} style={{ marginLeft: '1rem' }}>Fechar</Button>
                </Box>
            ) : (
                <Typography variant="h6" style={{ marginBottom: '.8rem' }}>
                    Este chamado foi fechado. Para reabrir, entre em contato com o suporte.
                </Typography>
            )}

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Responder Chamado</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Sua Mensagem"
                        type="text"
                        fullWidth
                        value={userMessage} // Vincular o valor do TextField ao estado
                        onChange={e => setUserMessage(e.target.value)} // Atualizar o estado quando o usuário digita
                    />
                    <Button
                        variant="contained"
                        component="label"
                        style={{ marginTop: '1rem' }}
                    >
                        Anexar Arquivo
                        <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={e => setUserAttachments(e.target.files[0])} // Atualizar o estado com o arquivo anexado
                        />
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={submitMessage} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
};

export default SupportDetail;
