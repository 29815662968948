import React, { useState, useEffect, useReducer, useContext } from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import { format } from 'date-fns';

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTable: {
        '& thead th': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.light.main,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    },
}));

const SuportList = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [tickets, dispatch] = useReducer(reducer, []);
    const [newTicketModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/supports/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        history.push("/support/new");
    };

    const hadleEditContact = (supportId) => {
        history.push(`/support/ticket/${supportId}`);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
            />
            <MainHeader>
                <Title>{i18n.t("support.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("support.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {user.super === false ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenContactModal}
                        >
                            {i18n.t("support.buttons.add")}
                        </Button>
                    ) : null}
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table className={classes.customTable} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t("support.table.subject")}</TableCell>
                            <TableCell>{i18n.t("support.table.name")}</TableCell>
                            <TableCell>{i18n.t("support.table.priority")}</TableCell>
                            <TableCell>{i18n.t("support.table.status")}</TableCell>
                            <TableCell>{i18n.t("support.table.date")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {tickets.map((ticket) => (
                                <TableRow hover key={ticket.id} onClick={() => hadleEditContact(ticket.id)} style={{ cursor: "pointer" }}>
                                    <TableCell align="center">{ticket.subject}</TableCell>
                                    <TableCell>{ticket.name}</TableCell>
                                    <TableCell align="center">
                                        {ticket.priority === "low" && (
                                            <span style={{ color: "green" }}>Baixa</span>
                                        )}
                                        {ticket.priority === "medium" && (
                                            <span style={{ color: "orange" }}>Média</span>
                                        )}
                                        {ticket.priority === "high" && (
                                            <span style={{ color: "red" }}>Alta</span>
                                        )}
                                    </TableCell>
                                    <TableCell align="center" style={{ color: ticket.isClosed ? 'red' : 'green' }}>
                                        {ticket.isClosed ? 'Fechado' : 'Aberto'}
                                    </TableCell>
                                    <TableCell align="center">{format(new Date(ticket.updatedAt), 'dd/MM/yyyy HH:mm')}</TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default SuportList;
